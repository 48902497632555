<template>
  <RichTextRenderer :document="richText" :node-renderers="nodesRenderer" />
</template>

<script setup lang="ts">
import RichTextRenderer, {
  type NodesRenderer
} from 'contentful-rich-text-vue-renderer';
import type { Block, Document } from '@contentful/rich-text-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { TableBlock } from '@hypercodestudio/basler-components';
import type { VNode } from 'vue';
import MappedComponent from '~/components/MappedComponent.vue';
import Link from '~/components/contentful/Link.vue';

interface Props {
  richText: Document;
  hasLightVerticalHeader?: boolean;
  cols?: number;
  colsCentered?: boolean;
  contentCentered?: boolean;
}

const nodesRenderer: NodesRenderer = {
  [BLOCKS.EMBEDDED_ENTRY]: (node, key) =>
    h(MappedComponent, {
      key,
      item: node.data.target
    }),
  [INLINES.EMBEDDED_ENTRY]: (node, key) =>
    h(MappedComponent, {
      key,
      item: node.data.target
    }),
  [INLINES.ENTRY_HYPERLINK]: (node, key, next) => {
    return h(
      Link,
      {
        key,
        item: node.data?.target,
        useSlot: true
      },
      next?.(node.content as Block[], key, next)
    );
  },
  [INLINES.ASSET_HYPERLINK]: (node, key, next) => {
    return h(
      'a',
      {
        key,
        href: node.data.target.fields.file.url,
        download: true,
        target: '_blank'
      },
      next?.(node.content as Block[], key, next)
    );
  },
  [BLOCKS.TABLE_HEADER_CELL]: (node, key, next) => {
    return h(
      'th',
      { key, scope: 'col' },
      next?.(node.content as Block[], key, next)
    );
  },
  [BLOCKS.TABLE]: (node, key, next) => {
    const tableRows = node.content as Block[];

    if (!tableRows || tableRows.length === 0) {
      return;
    }

    const firstTableRow = tableRows[0];
    const renderTableHead = () =>
      h(
        'thead',
        {},
        h('tr', {}, next?.(firstTableRow.content as Block[], key, next))
      );

    const tableRowCollection = tableRows
      .slice(1)
      .reduce<(() => VNode)[]>((tableRowCollection, currentTableRow) => {
        const currentRowCells = currentTableRow.content as Block[];

        if (!currentRowCells || currentRowCells.length === 0) {
          return tableRowCollection;
        }

        const firstRowCell = currentRowCells[0];
        const thFirstRowCell = () =>
          h(
            'th',
            { scope: 'row' },
            next?.(firstRowCell.content as Block[], key, next)
          );

        const restRowCells = currentRowCells.slice(1);
        const tdRestRowCells: (() => VNode)[] = restRowCells.reduce<
          (() => VNode)[]
        >(
          (tdRestRowCells, currentElement) => [
            ...tdRestRowCells,
            () =>
              h('td', {}, next?.(currentElement.content as Block[], key, next))
          ],
          []
        );

        return [
          ...tableRowCollection,
          () => h('tr', {}, [thFirstRowCell(), tdRestRowCells.map((x) => x())])
        ];
      }, []);

    const renderTableBody = () =>
      h(
        'tbody',
        {},
        tableRowCollection.map((x) => x())
      );

    return h(
      TableBlock,
      {
        hasLightVerticalHeader: props.hasLightVerticalHeader ?? false,
        cols: props.cols ?? 12,
        colsCentered: props.colsCentered ?? true,
        contentCentered: props.contentCentered ?? false
      },
      () => h('table', {}, [renderTableHead(), renderTableBody()])
    );
  },
  text: ({ marks, value }, key, markRenderer) => {
    if (!marks.length) {
      // avoid hydration mismatches
      // XXX: why are "\r" included in response? not normalized in
      //  contentful? "\r" is not rendered on client side.
      return value?.replaceAll('\r', '');
    }

    const marksReversed = [...marks].reverse();

    return marksReversed.reduce(
      // XXX: proper typing
      (aggregate: any, mark, i) =>
        markRenderer[mark.type]([aggregate], `${key}-${i}`, h),
      value
    );
  }
};

const props = defineProps<Props>();
</script>
